#page-signin {
  background: url("../../resources/images/backgrounds/background01.jpg");
  background-size: cover;
}

.signin-info {
  background: url("../../resources/images/logos/awe-logo2.svg") no-repeat center center;
  height: 62px;
  margin-bottom: 60px;
}

.signin-info h1 {
  display: none;
}

.signin-container {
  margin: auto;
  width: 350px;
  padding: 40px 60px 60px 60px;
  border-radius: 8px;
  background-color: var(--surface-a);
}
